import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/c/:sn',
    name: 'c',
    component: () => import('../views/saveCard.vue')
  },
  {
    path: '/id/:url',
    name: 'id',
    component: () => import('../views/saveCard.vue')
  },
  {
    path: '/',
    component: () => {
      return import('../views/DashboardPage.vue')
    }
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('../views/RegisterPage.vue')
  },
  {
    path: '/activate/:sn',
    name: 'activate',
    component: () => import('../views/CardActivate.vue')
  },
  {
    path: '/phone',
    name: 'phone',
    component: () => import('../views/PhoneVerify.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/LoginPage.vue')
  },
  {
    path: '/editprofile',
    name: 'editprofile',
    component: () => import('../views/EditProfile.vue')
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import('../views/ProfilePage.vue')
  },
  {
    path: '/edit/:sn',
    name: 'edit',
    component: () => import('../views/EditCard.vue')
  },
  {
    path: '/themes/:sn',
    name: 'themes',
    component: () => import('../views/ThemesCard.vue')
  },
  {
    path: '/edit/options/:type/:sn/',
    name: 'options',
    component: () => import('../views/EditCardOptions.vue')
  },
  {
    path: '/statistics/:sn',
    name: 'statistics',
    component: () => import('../views/StatisticsPage.vue')
  },
  {
    path: '/statisticsall',
    name: 'statisticsall',
    component: () => import('../views/StatisticsAllPage.vue')
  },
  {
    path: '/bh/:sn',
    name: 'bh',
    component: () => import('../views/BusinessHours.vue')
  },
  {
    path: '/socialmedia/:sn',
    name: 'socialmedia',
    component: () => import('../views/SocialMedia.vue')
  },
  {
    path: '/custom/:sn',
    name: 'url',
    component: () => import('../views/EditCustom.vue')
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('../views/DashboardPage.vue')
  },
  {
    path: '/request',
    name: 'request',
    component: () => import('../views/bindingRequests.vue')
  },
  {
    path: '/card',
    name: 'card',
    component: () => import('../views/CardView.vue')
  },
  {
    path: '/add',
    name: 'add',
    component: () => import('../views/AddCard.vue')
  },
  {
    path: '/addcardsn',
    name: 'addcardsn',
    component: () => import('../views/AddCardUseSN.vue')
  },
  {
    path: '/tap',
    name: 'tap',
    component: () => import('../views/TapCard.vue')
  },
  {
    path: '/changepassword',
    name: 'changepassword',
    component: () => import('../views/ChangePassword.vue')
  },
  {
    path: '/forgotpasswordbyemail',
    name: 'forgotpasswordbyemail',
    component: () => import('../views/ForgotPasswordbyEmail.vue')
  },
  {
    path: '/forgotpasswordbyphone',
    name: 'forgotpasswordbyphone',
    component: () => import('../views/ForgotPasswordbyPhone.vue')
  },
  {
    path: '/resetpassword/:verify_token',
    name: 'resetpassword',
    component: () => import('../views/ResetPassword.vue')
  },
  {
    path: '/reset-password/:verify_user_token',
    name: 'reset-password',
    component: () => import('../views/ResetPasswordLink.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  routes,
  base: process.env.BASE_URL
})

function checkIfUserIsNotAuthenticated() {
  if(localStorage.getItem("token") === null||localStorage.getItem("token")===''){
    return true
  }
  else{
    return false /* your authentication check */;
  }
}

router.beforeEach((to, from, next) => {
  const notAuthRoutes = ["register", "forgotpasswordbyemail","forgotpasswordbyphone","resetpassword", "reset-password","login","c"]; // Add your authentication routes here

  if (!notAuthRoutes.includes(to.name)) {
    // This is an authentication route
    // Perform your authentication logic here
    // Example: check if the user is authenticated
    const isNotAuthenticated = checkIfUserIsNotAuthenticated();

    if (isNotAuthenticated) {
      // User is already authenticated, redirect to another page
      if (to.name === from.name) {
        // Redundant navigation to the current location, do nothing
 
        next('login');
      }
      
      next('login');
    } else {
      // User is not authenticated, allow access to the authentication route
      next();
   
    }
  } else {
    // This is not an authentication route
    next();
  }
});


export default router
