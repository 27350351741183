import Vue from 'vue'
import './plugins/bootstrap-vue'
import App from './App.vue'
import store from './store'
import router from './router'
import VueMeta from 'vue-meta'

import '@fortawesome/fontawesome-free/css/all.css'

import VueSweetAlert from 'vue-sweetalert' 

/** Import VueTimePIcker */
import VueTimepicker from 'vue2-timepicker'
import 'vue2-timepicker/dist/VueTimepicker.css'
import moment from 'moment';
import OtpInput from "@bachdgvn/vue-otp-input";
import { Cropper } from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';
import 'choices.js/public/assets/styles/choices.css';

Vue.filter('moment', (value, format) => {
  // Use Moment.js to format the value with the specified format
  return moment(value).format(format);
});
Vue.component("v-otp-input", OtpInput);
Vue.use(VueSweetAlert);
Vue.use(VueTimepicker);
Vue.use(Cropper);
Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true
})

Vue.config.productionTip = false

new Vue({
  store,
  router,
  render: h => h(App)
}).$mount('#app')
