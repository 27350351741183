<template>
	<div id="app">
		<router-view />

		<footer class="my-4">
			<p>
				© 2007 - {{current_year}} NEWPAGES NETWORK SDN BHD <br> (1033406-U)
			</p>
		</footer>
		<footer v-if="isLogout" class="mb-2">
			<p>
				<router-link to="/register" class="footer-register">Register</router-link> 
				| 
				<router-link to="/login" class="footer-login">Sign In</router-link>
			</p>
		</footer>
	</div>
</template>

<script>
export default {
	computed: {
		isLogout() {
			return this.$route.name === "c" && !localStorage.getItem("token");
		},
	},
	data() {
		return {
			current_year: new Date().getFullYear()
		}
	}
};
</script>
